@import 'scss/shared.scss';

$checkboxSize: 20px;

.customCheckbox {

  .customInputContainer {
    min-height: $input-min-height;
    padding: 0 $padding-small;
    border: $border-global;
    display: flex;
    align-items: center;
  }

  label {
    display: block;
  }

  input[type="checkbox"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .checkbox {
    display: inline-block; // set to `inline-block` as `inline elements ignore `height` and `width`
    height: $checkboxSize;
    width: $checkboxSize;
    background: $color-white;
    border: $checkbox-border-size $color-checkbox-border solid;
    margin-right: $margin-xSmall;
    vertical-align: middle;

    svg {
      visibility: hidden;
      width: $checkboxSize;
      height: calc($checkboxSize/ 2 + 1px);
      padding-bottom: 4px;

      &.checkboxActive {
        visibility: visible;  
      }
    }
  }
}
