@import "scss/base.scss";

.container {
  display: flex;
  gap: 30px;

  .componentContainer {
    @include border;
    padding: $padding-large;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: $margin-medium;
  
    .innerContainer {
      @include border;
      padding: $padding-large;
    }
  }
}

.studyHeader {
  display: flex;
  align-items: center;
  gap: $margin-xlarge;
}

.appContainer {
  @include row;
  flex-wrap: wrap;
  padding: $site-horizontal-padding;
  gap: $margin-large;
  width: 85%;
}

.navigationContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 15%;
  min-width: 150px;
  padding: 16px;
}

.contentWrapper {
  display: flex;
  width: 100%;
}