@import "scss/base.scss";

.table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    color: black;
    width: 90%;
    align-items: center;
    margin-left: 60px;

 
  
  td, th {
    border: 1px solid #000000;
    text-align: left;
    padding: 10px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }

  .tableBtn {
    background-color: #bebebe;
    color: rgb(0, 0, 0);
    border: none;
    cursor: pointer;
    width: 60px;
    opacity: 0.8;
    border: 1px solid black;
    height: 30px;
  }

}

  /* 'Create New' button */
.open-button {
  background-color: rgb(172, 172, 172);
  color: black;
  //padding: 16px 20px;
  border: 1px solid black;
  cursor: pointer;
  opacity: 0.8;
  //position: fixed;
  bottom: 20px;
  right: 28px;
  width: 150px;
  margin-right: 10px;
  margin-bottom: 15px;
}

/* The popup form  */
.form-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  margin-right: 425px;
  margin-bottom: 300px;
  border: 3px solid #000000;
  background: #b4b4b4;
  z-index: 9;
  color: black;
}

.block {
  display: block;
}