@import "scss/base.scss";

.tabContainer {
    display: inline-block;
    padding-left: $padding-large;
    padding-right: $padding-large;
    border-right: $border-size solid $color-border-light-grey;
    font-size: $font-size-smallText;
    text-align: center;

    &.selected{
        background: $color-dm-darkBlue;
        color: $color-modal-text;
    }
    &:not(.selected) {
        svg {
            path {
                stroke: $color-border-light-grey;
            }
        }
    }

    svg {
        display: inline-block;
        vertical-align: -webkit-baseline-middle;
        height: calc(30px - $border-size * 2);
        width: 17px;
        padding-right: $padding-xSmall;
    }

    p {
        display: inline-block;
        vertical-align: middle;
        padding-top: $padding-small;
    }
}