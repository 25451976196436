@import "scss/base.scss";
@import "scss/base/tables.scss";

.container {
  display: flex;
  gap: 30px;
  margin: $margin-large;

  .typography {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .componentContainer {
    @include border;
    padding: $padding-large;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: $margin-medium;
  
    .innerContainer {
      @include border;
      padding: $padding-large;
    }
  }
}

.siteCheckupHeader {
  display: flex;
  align-items: center;
  gap: $margin-xlarge;
  margin: $margin-large;
}
