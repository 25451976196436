@import "scss/base.scss";


.dropdown {
  padding: 0 $padding-small;
  height: $input-min-height;
  position: relative;
  display: flex;
  border: solid 1px $color-border;

  .dropdownButton {
    background-color: transparent;
    appearance: none;
    border: none;
    font-weight: bold;
    padding: 0;
    cursor: pointer;
    @include row;
    gap: $margin-small;
    width: $full;

    span {
      width: $full;
      text-align: left;
    }
  }

  .dropdownContent {
    position: absolute;
    top: $input-min-height;
    left: 0;
    background-color: $color-white;
    z-index: 1;
    @include roundedFrame;

    .dropdownOption {
      @include clickableItem;
      padding: $padding-small $padding-xlarge;
      border-bottom: solid 1px $color-dm-lightGrey;

      &:hover {
        background-color: $color-dm-lightGrey;
      }
    }
  }

  .dropdownContentImg {
    display: flex;
    flex-wrap: wrap;
    width: $full;

    .dropdownOptionImage {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin: $margin-small $margin-xlarge;
      padding: $padding-xSmall;
      border: $border-size solid $color-border;
      width: 30%;
    }
  }

}


