.confirmationDialog {
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  max-width: 350px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.message {
  margin: 0;
  color: #666;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 8px;
}

.confirmButton,
.cancelButton {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.confirmButton {
  background-color: #007bff;
  color: white;

  &:hover {
    background-color: #0056b3;
  }
}

.cancelButton {
  background-color: #e9ecef;
  color: #495057;

  &:hover {
    background-color: #dde2e6;
  }
} 