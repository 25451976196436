@mixin cardStyle {
    background: $color-white;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.23), 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: $radius;
    transition: all 0.2s;

    &.gradient {
        background-image: linear-gradient(90deg, $color-blue 0%, $color-pink 100%);
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.23), 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border-radius: $radius;

        p {
            color: $color-white;
        }
    }

    &.blue {
        background-color: $color-blue;

        p {
            color: $color-white;
        }
    }

    &.pink {
        background-color: $color-pink;

        p {
            color: $color-white;
        }
    }

    &:hover:not(.blue):not(.gradient):not(.pink) {
        transition: all 0.2s;
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
}

.required,
.errorMessage {
    color: $color-form-error;
    font-size: $font-size-small;
    font-style: italic;
}