@import "scss/base.scss";

$tab-margin: 10px;
$header-padding: 5px;
$arrow-size: 35px;

.mapPanelContainer {
  @include mapPanelContainer;

  .header, .tabName {
    display: inline-block;
  }

  .header {
    border-bottom: $border-size solid $color-dm-grey;
    padding: $header-padding;
    width: calc($full - $header-padding * 2);
    margin-bottom: $header-padding;
    margin-top: $header-padding;

    &.noBorder {
      border-bottom: $border-none;
      margin-bottom: $margin-xSmall;
    }
  }

  .tabContainer {
    display: inline-block;
    width: calc($full - $arrow-size - $tab-margin * 2);
    overflow-x: scroll;
    white-space: nowrap;
    vertical-align: middle;
    margin-left: $tab-margin;
    margin-right: $tab-margin;

    .tabName {
      margin-left: $margin-small;

      &:first-of-type {
        margin-left: 0px;
      }
    }

    .selectedTab {
      color: $color-dm-darkBlue;
      font-weight: bold;
    }
  }
}

/* Media Queries */
// Tablet
@media only screen and (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
}
