@import "scss/base.scss";

$sliderHeight: 8px;

.sliderContainer {
  margin-top: $margin-small;
  padding-left: $padding-xSmall;
  color: $color-black;

  .label {
    display: inline-block;
  }

  .slider {
    display: inline-block;
    margin-left: $margin-small;
    vertical-align: middle;
    background: $color-dm-lightGrey;
    accent-color: $color-dark-grey;
    text-align: center;

    &::-webkit-slider-runnable-track {
      background: $color-dm-lightGrey;
      height: $sliderHeight;
      border-radius: $border-xLarge;

    }

    &::-moz-range-track {
      background: $color-dm-lightGrey;
      height: $sliderHeight;
      border-radius: $border-xLarge;
    }

    &::-webkit-slider-thumb {
      margin-top: calc(0px - $sliderHeight / 2); /* Centers thumb on the track */
      height: 32px;
      width: 16px;    
   }
  }
  
  .sliderValue {
    display: inline-block;
    margin-left: $margin-small;
    vertical-align: middle;
  }
}

/* Media Queries */
// Tablet
@media only screen and (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
}
