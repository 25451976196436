@import "scss/base.scss";

.container {
  display: flex;
  gap: 30px;

  .componentContainer {
    @include border;
    padding: $padding-large;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: $margin-medium;
  
    .innerContainer {
      @include border;
      padding: $padding-large;
    }
  }
}

.appContainer {
  @include row;
  flex-wrap: wrap;
  padding: $site-horizontal-padding;
  gap: $margin-large;
  width: 85%;
}

.navigationContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 15%;
  min-width: 150px;
  padding: 16px;
}

.contentWrapper {
  display: flex;
  width: 100%;
}

.actionColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deleteButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }
}

.deleteIcon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  color: #dc3545;
}