@import "scss/base.scss";

/* Add styles to the form container */
.form-container {
  max-width: 750px;
  min-width: 750px;
  //margin-right: 300px;
  padding: 10px;
  background-color: white;
}

/* input fields */
.form-container input[type=text] {
  width: 95%;
  right: 20px;
  padding: 15px;
  margin: 5px 0 22px 0;
  margin-right: 220px;
  border: none;
  background: #dadada;
}

/* When the inputs get focus, do something */
.form-container input[type=text]:focus, .form-container input[type=text]:focus {
  background-color: rgb(179, 179, 179);
  outline: none;
}

/* 'Save' button */
.form-container .btn {
  background-color: rgb(0, 161, 0);
  color: black;
  //padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 60%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* 'Close Form' button */
.form-container .cancel {
  background-color: rgb(0, 161, 0);
  color: black;
  //padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 60%;
  margin-bottom:10px;
  opacity: 0.8;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}
