@import "scss/base.scss";

.header {
    @include row;
    justify-content: space-between;
    padding: $padding-small $site-horizontal-padding;
    background-color: $color-dm-darkBlue;

    .logo {
        width: 100px;
    }
    
    .userHeaderContainer {
        display: flex;
        gap: $margin-medium;
        color: $color-white;

        .name {
            color: $color-white;
        }
    }
}
