@import 'scss/base.scss';

.baseLayout {
    display: flex;
    flex-direction: column;
    height: 100%;

    .layoutWrapper {
        flex: 1;
        display: flex;
    }
}