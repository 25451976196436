@import "scss/base.scss";

$banner-height: 400px;
$demo-image-height: 200px;

.layeredImagesContainer {
  height: $banner-height;
  position: relative;
  background-color: $color-ollon-orange;

  &::after {
    content: "";
    background-color: white;
    inset: 0;
    position: absolute;
    opacity: 0.3;
    pointer-events: none;
  }
  
  .demoImageContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    max-width: $section-max-width;
    margin: auto;
  
    .demoImage {
      object-fit: cover;
      width: $demo-image-height;
      height: $demo-image-height;
      bordeR: solid 1px $color-black;

      &.firstImage {
        position: absolute;
        transform: translate(25%, 25%);
      }

      &.secondImage {
        position: absolute;
        transform: translate(75%, 75%);
      }
    }

    .layeredImageList {
      background: white;
      padding-top: 20px;
      padding-bottom: 20px;
      border-radius: 8px;
      padding-right: 20px;
    }

  }
}

.uiElementsGrid {
  display: grid;
  grid-template: '. . . .';

  .uiInput {
    margin-bottom: $margin-large;
  }
}