$padding-none: 0;
$padding-xSmall: 0.25rem; // 4px
$padding-small: 0.5rem; // 8px
$padding-medium-small: 0.625rem;
$padding-medium: 0.75rem; // 12px
$padding-large: 1rem; // 16px
$padding-xlarge: 1.5rem; // 24px
$padding-xxlarge: 2rem; // 32px

$radius-small: 0.5rem; // 8px
$radius: 1rem; // 12px
$radius-large: 3rem; // 48px

$margin-tiniest: 0.2rem;
$margin-xSmall: 0.25rem; // 4px
$margin-small: 0.5rem; // 8px
$margin-medium-small: 0.625rem;
$margin-medium: 0.75rem; // 12px
$margin-large: 1rem; // 16px
$margin-xlarge: 1.5rem; // 24px

$font-size-small: 0.75rem;
$font-size: 1rem;
$border-none: 0px;
$border-size: 1px;
$checkbox-border-size: 2px;
$border-large: 3px;
$border-size-larger: 5px;
$border-xLarge: 10px;
$border-global: solid $border-size $color-dm-lightGrey;

$spacing-small: 0.5rem;
$spacing-medium: 0.75rem;
$spacing-large: 1rem;

// Media Query Sizing
$tablet-min-breakpoint: 768px;
$desktop-min-breakpoint: 1024px;
$tablet-max-breakpoint: calc(#{$desktop-min-breakpoint} - 1px);

// Font sizing
// Standard base
:root {
    font-size: 16px;
}

// Font sizes
$font-size-h1: 1.40rem;
$font-size-h2: 1.25rem;
$font-size-h3: 1rem;
$font-size-h4: 1rem;
$font-size-h5: 0.85rem;
$font-size: 1rem;
$font-size-smallText: 0.85rem;
$font-size-microText: 0.5rem;

// Form sizes
$input-font-size: 1.125rem;
$input-min-height: 30px;
$button-min-height: $input-min-height;
$button-radius: 4px;
$button-horizontal-padding: 30px;
$button-vertical-padding:10px;
$input-min-height-small: 30px;
$section-max-width: 768px;
$radius-rounded-corners: 8px;

// CTA Buttons
$cta-button-vertical-padding: 5px;
$cta-button-horizontal-padding: 15px;

// Site sizing
$site-horizontal-padding: $margin-xlarge;
$full: 100%;
$half: 50%;
$user-icon-size: calc(30px - 2px);

$icon-size: 1rem;
$icon-size-large: 1.25rem;
$font-size-large: 1.25rem;