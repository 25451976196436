@import '../base.scss';

$cell-height: 30px;

// Tables
.table {
    width: $full;
    border-collapse: collapse;
  
    &.userTable {
      border-top: solid 1px $color-border;
    }
  
    th,
    td,
    tr {
      border: none;
    }
  
    th {
      text-align: left;
      color: $color-dm-darkBlue;
      padding-bottom: $padding-small;
      border-bottom: solid 1px $color-border;
  
      &:first-of-type {
        padding-left: $padding-xxlarge;
      }
  
      &:last-of-type {
        padding-right: $padding-xxlarge;
      }
    }
  
    td {
      position: relative;
      padding: 0;
  
      &:first-of-type {
        padding-left: $padding-xxlarge;
      }
  
      &:last-of-type {
        padding-right: $padding-xxlarge;
      }
    }
  
    td > div {
      min-height: $cell-height;
      max-height: $cell-height;
      line-height: $cell-height;
      padding: $padding-medium 0;
      width: $full;
      height: $full;
      border-bottom: solid 1px $color-border;
    }
  }
  
  .roleDropdown {
    width: 300px;
  }
  
  .userIconRow {
    @include row;
    justify-content: center;
  }
  
  .userIconRowContainer {
    @include row;
    width: $user-icon-size * 3;
  }
  
  .userIcon {
    @include userIcon;
  }

//  Nested Table Styles
.dataGroup {
    border-bottom: solid 1px $color-border;
    padding: $padding-small $padding-xlarge;

    &:hover {
        background-color: $color-dm-lightBlue;
        color: $color-dm-darkBlue;
    }
}

.nestedDataTableRow {
    @include row;
    gap: $margin-small;
    border-bottom: solid 1px $color-border;
    padding: $padding-small $padding-medium;
}

.accessButton {
    width: 50px;
    text-align: center;
}