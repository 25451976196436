@import "scss/base.scss";

.labels {
   width: 100%;
   display: inline-block;
}

.field {
    margin-right: $margin-small;
}

.errorMessage {
    color: $color-form-error;
    font-size: $font-size-smallText;
    font-style: italic;
    margin-bottom: $margin-large;
}