@import "scss/base.scss";

.toggleLabel {
  margin-right: $margin-small;
}

.radio:checked,
.radio:not(:checked) {
  position: absolute;
  left: -9999px;
}
.radio:checked + label,
.radio:not(:checked) + label {
  position: relative;
  padding: 0px $padding-small;
  cursor: pointer;
  display: inline-block;
}

.radioContainer:first-of-type .radio + label {
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
}

.radioContainer:last-of-type .radio + label {
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
}

.radio + label {
  height: $input-min-height;
  border: solid 1px $color-border;
  line-height: $input-min-height;
}

.radio:checked + label {
  background-color: $color-ollon-orange;
  border-color: $color-ollon-orange;
  color: white;
}

.booleanOptions {
  flex-wrap: nowrap;
  display: flex;
}

.toggleStyle {
  display: flex;
  align-items: center;
}

.required,
.errorMessage {
  color: $color-form-error;
  font-size: $font-size-smallText;
  font-style: italic;
  margin-bottom: $margin-large;
}

.errorField {
  border-color: $color-form-error;
}

/* Media Queries */
// Tablet
@media only screen and (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
}
