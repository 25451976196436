@import "scss/base.scss";

$headerHeight: 30px;
$leftNavWidth: 64px;
$leftWidth: 255px;
$rightWidth: 300px;
$offsetHeight: 96px;
$areaHeight: calc($full - $offsetHeight);

.mapPage {
    height: $full;
   .navigationContainer {
        height: $headerHeight;
        border-top: $border-size solid $color-border-light-grey;
        border-bottom: $border-size solid $color-border-light-grey;
   }

   .contentContainer {
        .left {
            height: $areaHeight;
            width: $leftWidth;
            position: relative;
            z-index: 999;
            .controlPanelContainer {
               @include controlPanelContainer;
                
                .body {
                    max-height: 200px;
                    height: auto;
                    padding-left: $padding-small;
                    padding-bottom: $padding-xSmall;
                    width: calc($full - $padding-small);
                    overflow-y: scroll;
                }
            }

            .elementStyleContainer {
                width: $leftWidth;
                position: absolute;
                left: calc($full + $margin-small);
                top: 0px;
                overflow-y: scroll;
                padding-left: $padding-large;
                padding-right: $padding-large;
                padding-bottom: $padding-xlarge;

                .body {
                    height: calc($full - $padding-small);
                    padding-left: $padding-small;
                    padding-bottom: $padding-xSmall;
                    width: calc($full - $padding-small);
                    overflow-y: scroll;
                }
            }
        }

        .previewContainer {
            background-color: white;
            width: calc($full - $leftNavWidth);
            height: calc($full - $offsetHeight);
            text-align: center;
            position: absolute;
            top: $offsetHeight;
            z-index: 1;

            img {
                width: $full;
                height: $full;
            }

            p {
                display: inline;
            }
        }
    }

}