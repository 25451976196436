@mixin border {
    border: solid 1px $color-border;
    border-radius: 12px;
}

@mixin row {
    display: flex;
    align-items: center;
}

@mixin column {
    display: flex;
    flex-direction: column;
}

@mixin flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin clickableItem {
    cursor: pointer;
}

@mixin roundedFrame {
    border: solid 1px $color-dm-lightGrey;
    border-radius: 4px;
}

@mixin userIcon {
    background-color: $color-dm-grey;
    width: $user-icon-size;
    height: $user-icon-size;
    border-radius: 50%;
    position: relative;
    margin-left: -$user-icon-size + 15px;
    border: solid 1px $color-white;
    text-align: center;
    position: relative;
    line-height: $user-icon-size;
    
    &:first-child {
        margin-left: 0;
    }
}

@mixin modalFieldHeader {
    @include row;
    justify-content: space-between;

    .buttonRow {
        @include row;
        gap: $margin-medium;
    }
}

@mixin mapPanelContainer {
    color: $color-dm-grey;
    background-color: $color-white;
    width: $full;
    border-radius: 5px;
    padding: $padding-small;
    font-size: $font-size-smallText;
    opacity: 0.9;
}

@mixin controlPanelContainer {
    min-height: 100px;
    margin-top: 10px;
    margin-left: 10px;
    padding: 0;
    -webkit-box-shadow: 3px 4px 4px 3px rgba(0,0,0,0.08);
    -moz-box-shadow: 3px 4px 4px 3px rgba(0,0,0,0.08);
    box-shadow: 3px 4px 4px 3px rgba(0,0,0,0.08);
}

@mixin closeButton {
    position: absolute;
    right: 20px;
    cursor: pointer;
    width: 10px;
    height: 10px;
}