@import "scss/base.scss";

.leftNavContainer {
    background-color: $color-dm-lightGrey;
    display: flex;
    flex-direction: column;
    padding: $padding-small;
}

.iconContainer {
    text-align: center;
    margin-bottom: $margin-large;

    .icon {
        background-color: $color-dm-midGrey;
        border-radius: 8px;
        width: 40px;
        height: 40px;
        margin-bottom: $margin-small;
        @include flexCenter;

        &.isActive {
            background-color: $color-dm-darkBlue;
            svg path {
                fill: white;
            }
        }
    }
}