@import "scss/base.scss";

$headerHeight: 30px;
$leftNavWidth: 64px;
$leftWidth: 255px;
$rightWidth: 300px;
$offsetHeight: 96px;
$areaHeight: calc($full - $offsetHeight);

.mapPage {
    height: $full;
   .navigationContainer {
        height: $headerHeight;
        border-top: $border-size solid $color-border-light-grey;
        border-bottom: $border-size solid $color-border-light-grey;
   }

   .contentContainer {
        .left {
            height: $areaHeight;
            width: $leftWidth;
            position: relative;
            z-index: 999;
            .controlPanelContainer {
               @include controlPanelContainer;
                
                .body {
                    max-height: 200px;
                    height: auto;
                    padding-left: $padding-small;
                    padding-bottom: $padding-xSmall;
                    width: calc($full - $padding-small);
                    overflow-y: scroll;
                }
            }

            .elementStyleContainer {
                width: $leftWidth;
                position: absolute;
                left: calc($full + $margin-small);
                top: 0px;
                overflow-y: scroll;
                padding-left: $padding-large;
                padding-right: $padding-large;
                padding-bottom: $padding-xlarge;

                .body {
                    height: calc($full - $padding-small);
                    padding-left: $padding-small;
                    padding-bottom: $padding-xSmall;
                    width: calc($full - $padding-small);
                    overflow-y: scroll;
                }
            }
        }

        .previewContainer {
            background-color: white;
            width: calc($full - $leftNavWidth);
            height: calc($full - $offsetHeight);
            text-align: center;
            position: absolute;
            top: $offsetHeight;
            z-index: 1;

            img {
                width: $full;
                height: $full;
            }

            p {
                display: inline;
            }
        }
    }

}

.sliderContainer {
  margin: 1rem 0;
  
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
}

.sliderWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.slider {
  flex: 1;
  height: 2px;
  -webkit-appearance: none;
  background: #ddd;
  outline: none;
  
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #0066cc;
    cursor: pointer;
  }
  
  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #0066cc;
    cursor: pointer;
    border: none;
  }
}

.mapGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  height: 100%;
  width: 100%;
}

.mapWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 400px;
}

.deckGLControls {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 250px;
}

.map {
  width: 100%;
  height: 100%;
  :global(#deckgl-overlay) {
    position: relative !important;
  }
}

.clearButton {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #181871;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    background-color: #d32f2f;
  }
  
  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
}