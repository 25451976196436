/*
Try to utilize variables as much as possible.  It's one of the best things about a CSS preprocessor like Sass and it makes updates much easier
*/

//theme defaults
$color-ollon-orange: #F0793F;
$color-dm-blue: #009BD6;
$color-dm-lightBlue: #E0E6EF;
$color-dm-darkBlue: #063366;
$color-dm-lightGrey: #EAEAEA;
$color-dm-midGrey: #DBDBDB;
$color-dm-grey: #868686;

$color-alert: #ff0000;
$color-success: #b1df95;
$color-white: #ffffff;
$color-pink: #ffc0cb;
$color-blue: #0000ff;
$color-black: #242424;
$color-dark-grey: #4D4D4D;
$color-form-error: #D90038;

//  colour background
$color-background: #ffffff;
$color-background-white: #ffffff;
$color-background-secondary: #F0793F;
$color-highlight: #b1df95;
$color-box-shadow: #888888;
$color-background-footer: #b1df95;
$color-background-hover: #3F679F29;

// colour text
$color-text: #787878;
$color-text-secondary: $color-dm-blue;
$color-text-link: #ffc7aa;
$color-text-disabled: #787878;

// buttons
$color-button-background: $color-dm-blue;
$color-button-text: $color-white;
$color-button-text-confirm: #2B9307;
$color-button-text-alert: #C42F2F;
$color-button-text-cancel: $color-dm-grey;
$color-button-text-filter: #063366;

$color-button-background-disabled: #c7c7c7;
$color-button-border-blue: $color-dm-blue;
$color-button-border-green: $color-button-text-confirm;
$color-button-border-red: $color-button-text-alert;
$color-button-border-cancel: $color-button-text-cancel;
$color-button-border-filter: $color-button-text-filter;

// borders:
$color-border: #D9D9D9;
$color-border-blue: $color-button-text-filter;
$color-border-light-grey: #E6E6E6;
$color-drop-shadow: #000000;
$color-checkbox-border: #ddd;

// modals
$color-modal-background: #d48e6a;
$color-modal-text: #ffffff;

// header
$color-header-border: #b1df95;
$color-menu-text: #551d00;
$color-menu-background: #ffffff;

//flashtext
$color-flash-from: #d48e6a;
