@import "scss/base.scss";

.button {
  border-radius: $button-radius;
  cursor: pointer;
  font-size: $font-size;
  font-weight: 800;
  padding: $button-vertical-padding $button-horizontal-padding;
  appearance: none;
  min-height: $button-min-height;

  &:disabled {
    cursor: not-allowed;
  }

  &.primary {
    background-color: $color-button-background;
    border-color: $color-button-background;
    color: $color-white;
    border: none;

    &:hover {
      background-color: rgba($color-button-background, 0.75);
      border-color: rgba($color-button-background, 0.75);
    }

    &:disabled {
      background-color: rgba($color-button-background, 0.5);
      border-color: rgba($color-button-background, 0.5);

      &:hover {
        background-color: rgba($color-button-background, 0.25);
        border-color: rgba($color-button-background, 0.25);
      }
    }
  }

  &.secondary {
    color: $color-text-secondary;
    background-color: $color-background-white;
    border: 2.5px solid $color-button-border-blue;

    &:hover {
      color: rgba($color-text-secondary, 0.5);
      background-color: $color-white;
    }

    &:disabled {
      background-color: none;
      color: $color-dm-grey;
      border-color: rgba($color-button-background-disabled, 0.5);
      &:hover {
        background-color: rgba($color-button-background-disabled, 0.25);
        border-color: rgba($color-button-background-disabled, 0.25);
      }
    }
  }

  &.confirm {
    color: $color-button-text-confirm;
    background-color: $color-background-white;
    border: 2.5px solid $color-button-border-green;

    &:hover {
      color: rgba($color-button-text-confirm, 0.5);
      background-color: $color-white;
    }

    &:disabled {
      color: $color-dm-grey;
      border-color: rgba($color-button-background-disabled, 0.5);
      &:hover {
        background-color: rgba($color-button-background-disabled, 0.25);
        border-color: rgba($color-button-background-disabled, 0.25);
      }
    }
  }

  &.alert {
    color: $color-button-text-alert;
    background-color: $color-background-white;
    border: 2.5px solid $color-button-border-red;

    &:hover {
      color: rgba($color-button-text-alert, 0.5);
      background-color: $color-white;
    }

    &:disabled {
      color: $color-dm-grey;
      border-color: rgba($color-button-background-disabled, 0.5);
      &:hover {
        background-color: rgba($color-button-background-disabled, 0.25);
        border-color: rgba($color-button-background-disabled, 0.25);
      }
    }
  }

  &.cancel {
    color: $color-button-text-cancel;
    background-color: $color-background-white;
    border: 2.5px solid $color-button-border-cancel;

    &:hover {
      color: rgba($color-button-text-cancel, 0.5);
      background-color: $color-white;
    }

    &:disabled {
      color: $color-dm-grey;
      border-color: rgba($color-button-background-disabled, 0.5);
      &:hover {
        background-color: rgba($color-button-background-disabled, 0.25);
        border-color: rgba($color-button-background-disabled, 0.25);
      }
    }
  }
  &.block {
    color: $color-button-text-filter;
    background-color: $color-background-white;
    border: 2.5px solid $color-button-border-filter;
    border-radius: 0;

    &:hover {
      color: rgba($color-button-text-filter, 0.5);
      background-color: $color-white;
    }

    &:disabled {
      color: $color-dm-grey;
      border-color: rgba($color-button-background-disabled, 0.5);
      &:hover {
        background-color: rgba($color-button-background-disabled, 0.25);
        border-color: rgba($color-button-background-disabled, 0.25);
      }
    }
    &:active,
    &:focus {
      background-color: $color-border-blue;
      color: $color-white;
    }
  }

  &.iconButton-left {
    display: flex;
    gap: 3px;
  }

  &.iconButton-right {
    display: flex;
    flex-direction: row-reverse;
    gap: 3px;
  }
}

.linkButton {
  font-size: $font-size;
  text-decoration-line: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
}

.buttonIcon {
  margin-bottom: 2px;
}

.noneButton {
  cursor: pointer;
}
