@import "scss/base.scss";

.modalWrapper {
  width: fit-content;  // Changed from width: 50% !important
}

.dialogPanel {
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e5e7eb;
}

.title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.content {
  padding: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.confirmButton,
.cancelButton {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
  margin-bottom: 10px;
}

.confirmButton {
  background-color: #007bff;
  color: white;

  &:hover {
    background-color: #0056b3;
  }
  margin-right: 10px;
}

.cancelButton {
  background-color: #e9ecef;
  color: #495057;

  &:hover {
    background-color: #dde2e6;
  }
} 