@import "scss/base.scss";

.radioChoiceContainer {
    .radioOptions {
        flex-wrap: nowrap;
        display: flex;
        margin-top: $margin-tiniest;
    }

    &.stackedRadioLabel {
      flex-direction: column;
      align-items: flex-start;

      .radioOptions {
        margin: 0;
      }

      .radioLabel {
        margin-bottom: $margin-medium-small;
      }
    }
}

.errorField {
    .radioLabel {
        color: $color-form-error;
    }
}

.description {
  margin-bottom: $margin-medium-small;
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .radioChoiceContainer {
        display: flex;
        align-items: center;
    
        .radioOptions {
            flex: 1;
            flex-wrap: nowrap;
            display: flex;
            margin-top: 0px;
            margin-left: $margin-medium-small;
        }
    }
}

.required, .errorMessage {
  color: $color-form-error;
  font-size: $font-size-small;
  font-style: italic;
  min-height: $font-size-large;
}

.displayNone {
  display: none;
}

.errorField {
  border-color: $color-form-error;
}
