@import "scss/base.scss";
@import "scss/forms.scss";
@import "scss/icons.scss";

.singleInputCard {
    @include cardStyle;

    padding: $padding-medium-small;
    width: $full;

    p { 
        color: $color-white;
        font-size: $font-size-small;
        margin-bottom: $margin-tiniest;
    }

    .inputContainer {
        @include input-container;
    }

    .arrowIcon {
        width: $icon-size;
        height: $icon-size;
    }
}

.flex {
   display: flex;
   align-items: baseline;
}

.timeRangeContainer {
    .dash {
        display: flex;
        align-items: center;
        padding: $padding-none $padding-small;
    }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
}
