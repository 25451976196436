@import "scss/base.scss";

$svgOffset: 60px;

.mapLayerGroupContainer {
  margin-top: $padding-xSmall;
  margin-bottom: $padding-xSmall;
  p {
    font-weight: bold;
    color: $color-black;
    display: inline-block;
    padding-left: $padding-xSmall;
  }

  div {
    margin-left: $padding-xSmall;

    p {
      font-weight: 100;
    }
  }

  > svg {
    display: inline;
    position: relative;
    left: $svgOffset;
  }
}

/* Media Queries */
// Tablet
@media only screen and (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
}
