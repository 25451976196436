@mixin input-container() {
    flex: 1;
    display: flex;
    background: white;
    border-radius: $radius-rounded-corners;
    align-items: center;
    padding: 0px $padding-medium-small;
    min-height: $input-min-height-small;

    .inputTextField {
        flex: 1;
    }

    input {
        flex: 1;
        -webkit-appearance: none;
        outline: none;
        background: none;
        border: none;
        font-size: $font-size-small;
    }

    // Desktop
    @media only screen and (min-width:$desktop-min-breakpoint) {
        min-height: $input-min-height;
    }
}

// General styles related to the forms
$small-width: 20%;
$medium-width: 45%;
$full-width: 100%;
$default-width: 30%;

.form {
    width: $default-width;
}

.field {
    margin-bottom: $margin-large;
    width: $full-width;
}

.fieldGroup {
    display: flex;
    justify-content: space-between;

    .leftField{
        margin-right: $margin-large;
    }
}

.checkboxField {
    width: $small-width;
}

.textField {
    width: $full-width;
}

hr.solid {
    border-top: $border-large solid $color-border;
    margin-bottom: $margin-large;
}