@import "scss/base.scss";
@import "scss/base/tables.scss";
@import "scss/base/colors.scss";

$batch-delete-button-font-size: 12px;

.batchDeleteButton {
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  color: $color-button-text-alert;
  background-color: transparent;
  font-size: $batch-delete-button-font-size;
  text-wrap: nowrap;
  border: none;
  cursor: pointer;
}