@import "scss/base.scss";
@import "scss/forms.scss";

.halfLength{
    width: $medium-width;
}

.buttonGroup {
    display: flex;
    gap: 16px;
    margin-top: 24px;
  }
  
  .cancelButton,
  .submitButton {
    flex: 1;
    max-width: 200px;
  }