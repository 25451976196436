@import "scss/base.scss";
@import "scss/forms.scss";

.halfLength {
    width: $medium-width;
}

.refDocForm {
    width: $full-width;
}

.refDocSection {
    padding: 0 $padding-medium;
    border-right: $border-large solid $color-border;
}

.buttonGroup {
    display: flex;
    gap: 16px;
    margin-top: 24px;
  }
  
  .cancelButton,
  .submitButton {
    flex: 1;
    max-width: 200px;
  }

.autocompleteContainer {
  position: relative;
  flex: 1;
  
  &:first-child {
    margin-right: 1rem;
  }
}

.autocompleteDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.autocompleteItem {
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
}