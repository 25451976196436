@import "scss/base.scss";

.leftArrow, .rightArrow {
    margin: $margin-xSmall;
    position: relative;
    top: $margin-xSmall;
    cursor: pointer;
    
    &.bold {
        path {
         fill: $color-black;
        }
      }
      path {
        display: inline-block;
      }
  }