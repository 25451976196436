@import "scss/base.scss";

:export {
  primaryColor: $color-blue;
  borderColor: $color-border;
  hoverColor: $color-dm-lightGrey;
  textColor: $color-black;
  placeholderColor: #757575;
  whiteColor: $color-white;
  transparentColor: transparent;
  borderRadius: 4px;
  paddingX: 8px;
  paddingY: 2px;
  marginLeft: 2px;
  gap: 8px;
}

.selectWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
}

.label {
  font-weight: 500;
}

.select {
  width: 100%;
}

.control {
  border-radius: var(--borderRadius);
  box-shadow: none;
}

.option {
  cursor: pointer;
}

.singleValue {
  margin-left: var(--marginLeft);
}

.valueContainer {
  padding: var(--paddingY) var(--paddingX);
}

.optionWithThumbnail {
  display: flex;
  align-items: center;
  gap: 8px;
  
  .thumbnail {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
  }
} 