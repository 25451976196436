.colorPickerContainer {
  position: relative;
  margin-top: 10px;
}

.label {
  display: block;
  margin-bottom: 4px;
}

.swatch {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  border: 2px solid #ddd;
  cursor: pointer;
}

.popover {
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 2;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  background-color: white;
  padding: 8px;
  border-radius: 8px;
} 