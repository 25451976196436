@import "./base/colors.scss";
@import "./base/sizes.scss";
@import "./base/mixins.scss";
@import "./base/media-queries.scss";
@import "./base/components.scss";

html,
body,
#root {
  width: 100%;
  height: 100%;
}

code {
  white-space: pre-line;
  font-size: 85%;
}

li {
  margin-bottom: $margin-medium;
}

.codeContainer {
  background-color: $color-white;
  border-radius: 10px;
  padding: $padding-large;
}

.sideBySide {
  display: flex;

  .section {
    flex: 1;
    margin: $margin-small;
  }
}

.fullFlex {
  flex: 1;
}

.rowGapped {
  @include row;
  gap: $margin-medium;
}

.emphasize {
  font-weight: bold;
  color: $color-dm-darkBlue;
}

.fontBlue {
  color: $color-dm-darkBlue;
}

.textCenter {
  text-align: center;
}

.hide {
  display: none;
}

.greyed-out {
  color: $color-text-disabled !important;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: $font-size-h1;
  color: $color-dm-darkBlue;
  font-weight: 900;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: $font-size-h2;
}

h3 {
  font-size: $font-size-h3;
}

h4 {
  font-size: $font-size-h4;
}

h5 {
  font-size: $font-size-h5;
}

* {
  font-family: 'Avenir';
}

.dropshadow {
  -webkit-box-shadow: 3px 4px 4px 3px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 3px 4px 4px 3px rgba(0, 0, 0, 0.16);
  box-shadow: 3px 4px 4px 3px rgba(0, 0, 0, 0.16);
}

.outlineMode * {
  outline: 2px solid rgba(255, 0, 0, .6);

  :hover {
    outline: 5px solid rgba(255, 0, 0, 0.6);
  }
}

.outlineMode * * {
  outline: 2px solid rgba(0, 255, 0, .6);

  :hover {
    outline: 5px solid rgba(0, 255, 0, 0.6);
  }
}

.outlineMode * * * {
  outline: 2px solid rgba(0, 0, 255, .6);

  :hover {
    outline: 5px solid rgba(0, 0, 255, 0.6);
  }
}

.outlineMode * * * * {
  outline: 2px solid rgba(255, 0, 255, .6);

  :hover {
    outline: 5px solid rgba(255, 0, 255, 0.6);
  }
}

.outlineMode * * * * * {
  outline: 2px solid rgba(0, 255, 255, .6);

  :hover {
    outline: 5px solid rgba(0, 255, 255, 0.6);
  }
}

.outlineMode * * * * * * {
  outline: 2px solid rgba(255, 251, 4, 0.6);

  :hover {
    outline: 5px solid rgba(255, 251, 4, 0.6);
  }
}

.transparent {
  background: transparent;
}