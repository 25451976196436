@import "scss/base.scss";
@import "scss/base/colors.scss";
@import "scss/base/sizes";

.navDropdownMenu {
  position: absolute;
  right: 0;
  background-color: $color-white;
  border: $border-size solid $color-border;
  border-bottom: $border-size-larger solid $color-dm-darkBlue;
  z-index: 1;

  .navDropdownOption {
    display: block;
    padding: $padding-large $padding-xxlarge;
    color: $color-black;
    text-decoration: none;
    border: $border-size solid $color-border;

    &:hover {
      background-color: $color-background-hover;
    }
  }
}
