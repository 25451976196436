@import "scss/base.scss";
@import "scss/base/tables.scss";

table tr.contactsTableRow {
  border-top: solid 1px $color-border;

  // Overrides a border style inherited from tables.scss
  td > div {
    border: none;
  }

  .checkboxCell {
    width: 5%;
  }

  .userIconCell {
    width: 25%;

    .userIconRowContainer {
      gap: 10px;
    }
  }

  .emailCell {
    width: 25%;
  }

  .addToOrganizationButtonContainer {
    display: flex;
    justify-content: center;
    
    .addToOrganizationButton {
      width: fit-content;
    }
  }
}