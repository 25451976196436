@import "scss/base.scss";
@import "scss/forms.scss";

.halfLength{
    width: $medium-width;
}

.autocompleteContainer {
  position: relative;
  width: 100%;
}

.autocompleteDropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.autocompleteItem {
  padding: 8px 12px;
  cursor: pointer;
  
  &:hover {
    background-color: #f5f5f5;
  }
}

.buttonGroup {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.cancelButton,
.submitButton {
  flex: 1;
  max-width: 200px;
}