@import "scss/base.scss";

.loginPage {
    @include row;
    height: $full;

    .left {
        height: $full;
        background-color: $color-dm-darkBlue;
        flex: 1;
    }

    .right {
        padding: $padding-xlarge;
        flex: 1;
        @include column;
        gap: $margin-large;
    }
}