@import "scss/base.scss";

.nestedModalContainer {
    max-width: 75%;
    min-height: 50%;
    @include column;

    .modalHeader {
        padding: $padding-xxlarge;
    
        .titleContainer {
            @include row;
            justify-content: space-between;
        }
    
        .tableLabel {
            margin-top: $margin-large;
        }
    }
    
    .modalDataContainer {
        @include row;
        border-top: solid 1px $color-border;
        flex: 1;
        align-items: flex-start;
    
        .nestedDataGroupColumn {
            flex: 0.5;
            border-right: solid 1px $color-border;
            align-self: stretch;
            padding-bottom: $padding-xxlarge;
        }
    
        .nestedDataColumn {
            flex: 1.5;
            padding-bottom: $padding-xxlarge;
        }
    }
}

