@import "scss/base.scss";

.filterSort {
  @include row;
  justify-content: space-between;
  gap: $margin-small;

  .filterDropdown {
    border: solid 1px $color-dm-darkBlue;

    button {
        color: $color-dm-darkBlue;
    }
  }

  .dropdownArrow {
    width: 30px;
  }
}