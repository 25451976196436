@import "scss/base.scss";

.header {
  @include row;
  justify-content: space-between;
  width: $full;
  padding: $padding-xlarge $site-horizontal-padding;
  border-bottom: solid 1px $color-dm-lightGrey;

  .headerTitleCTA, .filterSortSearch {
    @include row;
    gap: $margin-medium;
  }
}
