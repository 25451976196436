@import 'scss/base/tables.scss';

.table {
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 10px 10px;
    vertical-align: middle;
  }

  th {
    padding: 10px 10px;
    vertical-align: middle;
  }
}

.tableRow {
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.cellContent {
  &.textLeft {
    text-align: left;
  }
  
  &.textCenter {
    text-align: center;
  }

  &.textRight {
    text-align: right;
  }
}

.dataTable {
  margin-top: $margin-medium;
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  th {
    background-color: #f4f4f4;
  }
}