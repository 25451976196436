@import "scss/base.scss";
@import "scss/forms.scss";
@import "scss/icons.scss";

.singleInputCard {
    @include cardStyle;

    padding: $padding-medium-small;
    width: $full;

    p { 
        color: $color-white;
        font-size: $font-size-small;
        margin-bottom: $margin-tiniest;
    }

    .inputContainer {
        @include input-container;
    }

    .arrowIcon {
        width: $icon-size;
        height: $icon-size;
    }
}

.singleDateInput {
    flex: 1;

    .inputContainer {
        display: flex;
        box-shadow: none;
        align-items: center;
        width: $full;
    
        .calendarIcon {
            height: $icon-size;
            width: $icon-size;
            margin-left: calc(-#{$margin-medium-small} -#{$icon-size});
            margin-right: $margin-medium-small;
            flex: initial;
        }
    }

    :global(.react-datepicker-wrapper) {
        display: flex;
        :global(.react-datepicker__input-container) {
            display: flex;
        }
    }
}

.inputWrapper {
    display: flex;

    input {
        height: $input-min-height-small;
        flex: 1;
        width: $full;
        font-size: $font-size-small;
    }
}

/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .singleDateInput {
        .inputContainer {
            .calendarIcon {
                height: $icon-size-large;
                width: $icon-size-large;
                margin-left: calc(-#{$margin-medium-small} -#{$icon-size-large});
            }
        }
    }
}
