@import "scss/base.scss";

.userInfoContainer {
  @include row;
  gap: $margin-small;

  .userLabel {
    line-height: 1rem;
  }

  .userIcon {
    @include userIcon;
  }
}
