@import "scss/base.scss";

$boxSize: 20px;
$leftWidth: 255px;
$opacityWidth: 48px;
$percentageWidth: 27px;

.editMapContainer {
    .settingContainer, .settingBorderContainer {
        color: $color-black;

        p {
            display: inline-block;
        }

        > p {
            padding-left: $padding-xSmall;
        }
    }

    .settingBorderContainer {
        margin-top: $margin-large;
        display: flex;
        justify-content: space-between;

        P {
            padding-top: $padding-small;
        }
    
    }

    .slider {
        width: calc($leftWidth - $opacityWidth - $padding-small - $border-size * 2 - $padding-small - $percentageWidth - $padding-large);
    }

    .editOptions {
        margin-top: $margin-small;
        margin-bottom: $margin-small;

        div {
            width: $full;
        }
    }

    .settingContainer {
        display: flex;
        justify-content: space-between;
        margin-top: $margin-xlarge;

        p {
            display: flex;
        }

        .colourContainer {
            display: flex;
            
            p {
                width: 60px;
            }

            .box {
                display: inline-block;
                height: $boxSize;
                width: $boxSize;
                border: 0;
                border-radius: $border-large;
                vertical-align: middle;
                margin-left: $margin-small;
            }
        }
    }

    .applyContainer, .applySaveContainer {
        width: $full;
        p {
            font-weight: bolder;
        }
    }

    .applyContainer {
        margin-top: $margin-xlarge;
        margin-bottom: $margin-small;
    }

    .applySaveContainer {
        background-color: $color-white;
        color: $color-dm-blue;
        border: $border-large solid $color-dm-blue;

        p {
            position: relative;
            top: -$border-large;
        }
    }

    .borderWidthContainer {
        padding-top: 0;
    }
}