@import "scss/base.scss";

.infoBlock {
  padding: $padding-large;
  margin: auto;
  border-top: solid $border-size $color-border;

  &.fullWidth {
    padding: $padding-large 0;
  }
}

/* Media Queries */
// Tablet
@media only screen and (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
  .infoBlock {
    max-width: $section-max-width;

    &.fullWidth {
      max-width: initial;
      .title, .subtitle {
        max-width: $section-max-width;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
