@import "scss/base.scss";


.textField {
  @include row;
  flex-wrap: wrap;

  .label {
    margin-right: $margin-medium;
    font-size: $font-size-smallText;
  }

  .inputFieldContainer {
    border: $border-size solid $color-border;
    height: $input-min-height;
    padding: 0 $padding-medium;
    @include row;
  
    .field {
      border: none;
      outline: none;
      appearance: none;
      width: $full;
    }
  }

  .alertContainer{
    flex-basis: 100%;
    display: flex;
    .required,
    .alert {
      color: $color-alert;
    }
  }
}

