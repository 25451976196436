@import "scss/base.scss";
@import "scss/forms.scss";
@import "scss/icons.scss";

.dateTime {
    width: $full;
}

.singleDateTime {
    display: flex;
    align-items: center;
}

.dateRangeType {
    margin-bottom: $margin-medium-small;
}

.errorField {
    p {
        color: $color-form-error;
    }
  }


/* Media Queries */
// Tablet
@media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {

}

// Desktop
@media only screen and (min-width:$desktop-min-breakpoint) { 
    .dateTime {
        label {
            color: $color-dm-grey;
        }
    }
}