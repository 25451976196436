@import "scss/base.scss";

.modalHeader {
    padding: $padding-xxlarge;

    .titleContainer {
        @include row;
        justify-content: space-between;
        margin-bottom: $margin-large;
    }

    .listSearchHeader {
        @include row;
        justify-content: space-between;
        margin: $margin-xlarge 0;

        .tableLabel {
            margin-top: $margin-large;
        }
    }
}

.fieldHeader {
    @include modalFieldHeader;
}

