@import "scss/base.scss";

$search-bar-width: 350px;

.contactsPageContainer {
  height: $full;
  width: auto;

  .header {
    display: flex;
    flex-direction: column;
    padding-top: 75px;
    flex: 1 1 auto;

    .headerRow {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      padding: 10px 0px 5px 50px;
      gap: 20px;
    }

    .firstHeaderRow {
      justify-content: start;
    }
  
    .secondHeaderRow {
      justify-content: center;

      .searchBar input {
        width: $search-bar-width;  
      }
    }
  }

  .contactsTable {
    padding-top: 20px;
  }
}
