@import "scss/base.scss";

.heroBanner {
  width: 100%;
  padding: $padding-large;
  border: solid 10px $color-ollon-orange;
  box-sizing: border-box;

  .bannerContent {
    display: flex;
    align-items: center;
    justify-content: center;

    .textContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .bannerImage {
      width: 400px;
      height: 370px;
    }
  }
}
