@import "scss/base.scss";

$project-tile-min-width: 345px;
$project-tile-menu-icon-width: 15px;

.projectTile {
  min-width: $project-tile-min-width;
  @include border;

  .projectMeta,
  .projectAccess,
  .projectBottomInfo {
    @include row;

    .menuIcon {
      width: $project-tile-menu-icon-width;
      padding: $padding-small;
      cursor: pointer;
    }
  }

  .projectMeta {
    padding: $padding-large;
    align-items: flex-start;
    border-bottom: solid 1px $color-border;
    gap: $margin-medium;

    .projectMetaText {
      flex: 1;
    }
  }

  .projectBottomInfo {

    .projectAccess {
      border-right: solid 1px $color-border;
      flex: 1;
      padding: $padding-large;
      gap: $margin-small;
    }

    .projectInfo {
      flex: 1;
      padding: $padding-large;
    }
  }
}
