@import "scss/base.scss";

.numberContainer {
  padding: $padding-xSmall $padding-xSmall $padding-xSmall $padding-medium;
  color: $color-black;
  display: inline-block;
  border: $border-size solid $color-dm-grey;

  .number { 
    padding-right: $padding-medium;
  }

  svg {
    position: relative;
    
    &:first-of-type {
      top: 3px;
      left: 1px;
    }

    &:last-of-type {
      bottom: 3px;
      right: 7px;
    }
  }
}

/* Media Queries */
// Tablet
@media only screen and (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
}
