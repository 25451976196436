@import "scss/base.scss";

.modalWrapper {
    position: absolute;
    padding: 0;
    inset: 0;
    margin: auto;
    background-color: white;
    -webkit-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.16);
    max-width: 890px;
    height: fit-content;
    border: solid 1px $color-border;
    border-radius: 8px;
}