@import "scss/base.scss";

$svg-size: 18px;
$button-size: 20px;

.mapLayerContainer {
  padding: $padding-xSmall;

  .mapLayerNameContainer {
    display: inline-block;
    vertical-align: middle;
    padding-left: $padding-medium;
    color: $color-black;
  }

  .box {
    display: inline-block;
    height: $button-size;
    width: $button-size;
    border: $border-size solid;
    border-radius: $border-large;
    vertical-align: middle;
  }

  .buttonContainer {
    text-align: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    padding-top: $padding-none;
    padding-bottom: $padding-none;
    border-bottom: $padding-none;
    max-height: $padding-none;

    &.hovered {
      padding-top: $padding-xSmall;
      padding-bottom: $padding-xSmall;
      border-bottom: $border-size solid $color-border-light-grey;
      max-height: $button-size;
    }

    svg {
      height: $svg-size;
      width: $svg-size;
      padding-left: $padding-large;
      cursor: pointer;
      &:first-of-type {
        padding-left: 0;
      }
    }
  }
}

/* Media Queries */
// Tablet
@media only screen and (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
}
