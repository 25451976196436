@import "scss/base.scss";

$app-tile-min-width: 345px;
$app-tile-menu-icon-width: 15px;

.appTile {
  min-width: $app-tile-min-width;
  @include border;

  .appMeta,
  .appAccess,
  .appBottomInfo {
    @include row;

    .menuIcon {
      width: $app-tile-menu-icon-width;
      padding: $padding-small;
      cursor: pointer;
    }
  }

  .appMeta {
    padding: $padding-large;
    align-items: flex-start;
    border-bottom: solid 1px $color-border;
    gap: $margin-medium;

    .appMetaText {
      flex: 1;
    }
  }

  .appBottomInfo {
    .appAccess {
      border-right: solid 1px $color-border;
      flex: 1;
      padding: $padding-large;
      gap: $margin-small;
    }

    .appInfo {
      flex: 1;
      padding: $padding-large;
    }

    .appAction {
      flex: 1;
      padding: $padding-large;
      text-align: center;
    }
  }
}
