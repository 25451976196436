@import "scss/base.scss";

.inputContainer {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: $margin-large;
}

.labels {
    margin-bottom: $margin-xSmall;
}

.field {
    width: calc($full - $margin-large * 2);
}