@import "scss/base.scss";

.section {
    flex: 1;
    padding: 20px;
}

.mapContainer {
    display: flex;
    justify-content: center;
    margin-bottom: $margin-large;
}

.map {
    height: 300px;
    width: $full;
}

.mapError {
    color: red;
    margin: 8px 0;
    padding: 8px;
    background-color: rgba(255, 0, 0, 0.1);
    border-radius: 4px;
}

.boundaryOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

.optionContent {
    display: flex;
    align-items: center;
    gap: 8px;
}

.thumbnail {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
}

.boundaryActions {
    display: flex;
    gap: 8px;
    align-items: center;
}

.saveButton,
.deleteButton {
    background: none;
    border: none;
    padding: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 16px;
        height: 16px;
    }

    &:hover {
        opacity: 0.8;
    }
}

.deleteButton {
    color: #ff4444;
}

.saveButton {
    color: #00c851;
}

.selectedBoundaryInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    border-radius: 4px;

    .boundaryDetails {
        .boundaryName {
            font-weight: 500;
        }
    }

    .deleteButton {
        background: none;
        border: none;
        cursor: pointer;
        padding: 4px;

        &:hover {
            opacity: 0.8;
        }
    }
}

.boundaryNameInput {
    font-size: inherit;
    font-family: inherit;
    padding: 2px 4px;
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 100%;
    max-width: 300px;
    background: white;

    &:focus {
        outline: none;
        border-color: #088;
        box-shadow: 0 0 0 2px rgba(0, 136, 136, 0.2);
    }
}

.boundaryName {
    cursor: text;
    user-select: none;
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.05);
    }
}

.featureDialogContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
}

.featurePreviewMap {
    width: 100%;
    height: 200px;
    border-radius: 4px;
    overflow: hidden;
    flex-shrink: 0;
}

.featureSelector {
    flex-shrink: 0;
    position: relative;

    :global {
        .select__menu {
            position: fixed !important;
            width: calc(100% - 3rem) !important;
            z-index: 100;
        }
    }
}

.propertyList {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;

    label {
        font-size: 0.875rem;
        font-weight: 500;
        color: #374151;
        margin-bottom: 0.5rem;
        display: block;
    }
}

.propertyTable {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
}

.propertyRow {
    display: flex;
    padding: 0.5rem;
    border-bottom: 1px solid #e5e7eb;
    
    &:last-child {
        border-bottom: none;
    }

    &:nth-child(even) {
        background-color: #f9fafb;
    }
}

.propertyKey {
    flex: 1;
    font-weight: 500;
    color: #374151;
}

.propertyValue {
    flex: 2;
    color: #6b7280;
}