@import "scss/base.scss";

.container {
  display: flex;
  gap: 30px;

  .componentContainer {
    @include border;
    padding: $padding-large;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: $margin-medium;
  
    .innerContainer {
      @include border;
      padding: $padding-large;
    }
  }
}

.siteCheckupHeader {
  display: flex;
  align-items: center;
  gap: $margin-xlarge;
}

.projectsContainer {
  @include row;
  flex-wrap: wrap;
  padding: $site-horizontal-padding;
  gap: $margin-large;
}