@import "scss/base.scss";

.radioContainer {
  &:first-of-type {
    .radio + label {
      border-top-left-radius: $radius-large;
      border-bottom-left-radius: $radius-large;
    }
  }

  &:last-of-type {
    .radio + label {
      border-top-right-radius: $radius-large;
      border-bottom-right-radius: $radius-large;
    }
  }

  .radioLabel {
    margin-right: $margin-small;
  }

  .radio.pill:checked,
  .radio.pill:not(:checked) {
    position: absolute;
    opacity: 0;
  }
  .radio.pill:checked + label,
  .radio.pill:not(:checked) + label {
    position: relative;
    padding: 0px $padding-large;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    margin: 0px;
  }

  .radio.pill + label {
    height: $input-min-height;
    border: solid $border-size $color-border;
    line-height: $input-min-height;
    background-color: $color-white;
  }

  .radio.pill:checked + label {
    background-color: $color-ollon-orange;
    border-color: $color-ollon-orange;
    color: $color-white;
  }

  .disabled + label {
    color: $color-button-background-disabled;
  }

  $radio-button-circle-size: 30px;

  // Standard Circle Version
  .radio.circleRadio:checked,
  .radio.circleRadio:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  .radio.circleRadio:checked + label,
  .radio.circleRadio:not(:checked) + label {
    position: relative;
    padding-left: 45px;
    cursor: pointer;
    line-height: $radio-button-circle-size;
    display: inline-block;
  }
  .radio.circleRadio:checked + label:before,
  .radio.circleRadio:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: $radio-button-circle-size;
    height: $radio-button-circle-size;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  .radio.circleRadio:checked + label:after,
  .radio.circleRadio:not(:checked) + label:after {
    content: "";
    width: $radio-button-circle-size - 8px;
    height: $radio-button-circle-size - 8px;
    background: $color-ollon-orange;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .radio.circleRadio:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .radio.circleRadio:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* Media Queries */
// Tablet
@media only screen and (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
}

// Desktop
@media only screen and (min-width: $desktop-min-breakpoint) {
}
