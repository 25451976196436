@import "scss/base.scss";

$user-initial-icon-size: 40px;

.userInitial {
    background-color: $color-dm-grey;
    color: $color-white;
    width: $user-initial-icon-size;
    height: $user-initial-icon-size;
    border-radius: $user-initial-icon-size * 2;
    line-height: $user-initial-icon-size;
    text-align: center;
    font-weight: bold;
}

.black {
    color: $color-black;
}

.white {
    color: $color-white;
}