@import "scss/base.scss";

.tableContainer {
  padding-bottom: $padding-xxlarge;
}

.tableRow {
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--hover-bg-color, #f5f5f5);
  }
}
